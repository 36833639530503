@import url('https://fonts.cdnfonts.com/css/montserrat');
@import url('https://fonts.cdnfonts.com/css/socake');

:root {

    --font-color: rgb(81, 16, 194);
}

body {

font-family: 'Montserrat', sans-serif;
background: url(img/scroll.jpg);

    
    padding-top: 80px;

}

html {

    scroll-behavior: smooth;
}



.navlink {

    text-decoration: none;
    color: var(--font-color);

}

.horizontal-padding {

    padding-left: 25px;
    padding-right: 25px;

}

.vertical-padding {

    padding-top: 25px;
    padding-bottom: 25px;
}

h1{

    font-family: 'Socake', sans-serif;
    text-align: center;
    padding: 0;
    margin-top: 30px;
    margin-bottom: 0;
    color: var(--font-color);
    font-size: 50px;

}

h2{
    margin: 15px 0;
    padding: 0;
}





@media (min-width:990px) {

    .horizontal-padding {

        padding-left: 50px;
        padding-right: 50px;

    }

    .vertical-padding {

        padding-top: 50px;
        padding-bottom: 50px;
    }
}

