.banner{

text-align: center;
display: flex;
max-width: 900px;
margin: 20px auto;
}

.banner img{

   
  
   height: 150px;

}

.banner h2{

   padding: 10px;
   margin: 10px;
}

.euro{
   font-size: 40px;
   color: red;
   font-family:Arial, Helvetica, sans-serif;
   font-weight:bolder;
   
}


@media (max-width:990px) {

   .banner{

      flex-direction: column;
     
   }

   .banner img{
      margin: 10px auto;
   }


}


