@import url('https://fonts.cdnfonts.com/css/luckie-bluchi');

.topheader {

    /* ejex  ejey difuminado color */
    box-shadow: 0 4px 5px rgb(181, 155, 235);
    background-color: aliceblue;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;

}

.topnav {

    display: flex;
    justify-content: space-between;
    vertical-align: middle;
    padding: 10px;
    max-width: 1200px;
    margin: 0 auto;


}

/*.logo {

    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 20px;


}*/

/*.logo .i {

    border-radius: 20%;
    padding-bottom: 10px;
    padding-right:  20px;
    padding-left: 20px;

}*/

.icon{
    font-size: 20px;
}

.logotype {
    display: flex;
    justify-content: center;
    vertical-align: middle;
    font-size: 40px;
    font-family: 'Luckie Bluchi', sans-serif;
    padding-top: 10px;
    text-shadow: 3px 3px 20px #ff99cc, -2px 1px 30px #ff99cc;
    text-decoration: none;
    color: black;
}

/*MENU*/
.menu {

    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    line-height: 60px;
    

}

.menu li {
    padding: 0 15px;

}

.menu ul{
    line-height: 40px;
}

.menu .navlink {

    font-size: 18px;
    font-weight: 500;    
    text-transform: uppercase;
    position: relative;

}

.menu .navlink::after {

    content: "";
    /* obligatorio*/
    background-color: rgb(52, 210, 213);
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    height: 4px;
    opacity: 0;
    transition: opacity 0.3s;

}

.menu .navlink.selected::after,
.menu .navlink:hover::after {
    opacity: 0.8;
}

.menu .navlink:hover {
    color: blueviolet;
}


.open-menu,
.close-menu {

    display: none;
}




@media (max-width: 990px) {

    .menu {
        
        flex-direction: column;
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        align-items: center;
        background-color: rgb(113, 98, 213);
        height: 100%;
        overflow-y: auto;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.3s;

    }

    .menu li {
        padding: 0;
    }

    .menu .navlink {
        color: aliceblue;
        line-height: 70px;
        font-size: 25px;


    }

    .open-menu,
    .close-menu {

        display: block;
        border: none;
        background: none;
        cursor: pointer;

    }

    .close-menu {

        align-self: flex-end;
        padding: 20px 15px;
    }

    .menu_opened {

        opacity: 1;
        pointer-events: all;
    }

    .open-menu:focus:not(:focus-visible),
    .close-menu:focus:not(:focus-visible) {
        outline: none;
    }

    .menu .navlink::after {
        content: none;

    }

    .menu .navlink:hover,
    .menu .navlink.selected {
        color: aqua;
    }

    .logotype {
        display: flex;
        justify-content: center;
        vertical-align: middle;
        font-size: 40px;
        font-family: 'Luckie Bluchi', sans-serif;
        padding-top: 10px;

       
    }

}

