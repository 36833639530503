.contacto-title {

    padding: 0;
    margin: 0;
}

.contacto{

    max-width: 600px;
    margin: 0px auto;
    padding-top: 30px;
    

}

.contacto-p {

    font-size: 22px;
    text-align: center;
}

.form-container {
    
    display: flex;
    flex-direction: column;   
    background-color: rgb(176, 164, 255);
    padding: 30px;
    border-radius: 15px;
    box-shadow: 0 4px 10px black;
    margin-top: 40px;
}

.form {

    margin: 30px;
    display: flex;    
    flex-direction: column;
    gap: 10px;
   
     
}

form input{

    height: 30px;
    font-size: 25px;
    padding: 5px;
}

form textarea{

    font-size: 25px;
    padding: 5px;
}

#button{

    border-radius: 10px;
    background-color: darkblue;
    padding: 10px;
    height: 50px;
    color: white;
    border: none;
    box-shadow: 0 4px 10px black;
    margin-top: 20px;
    cursor: pointer;
    
    
}

@media (max-width:990px) {

    .contacto{

        max-width: 90%;
        margin: 10px auto;
        padding-top: 30px;
        
    
    }


}