.graella{

    max-width: 80%;
    padding: 0;
    margin:  40px auto;
    display: grid;
    gap: 40px;
    grid-template-columns: repeat(auto-fill, minmax(250px,1fr));
    
    
}

.dibujo{

    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 10px black;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    

}

.nombre{

    font-size: 20px;
    text-transform: uppercase;

}

.dibujo-img{

    width: 100%;
    border-radius: 10px 10px 0 0;
    box-shadow: 0 4px 10px cornflowerblue;
    max-height: 300px;
    height: 220px;
    filter: brightness(1.2);
    
    
    
    
}

.subtitulo{

    position: absolute;
    background-color: rgba(118, 63, 219, 0.7);
    color: aliceblue;
    font-size: 20px;
    bottom:1%;
    right: 1%;
    border-radius: 10px;
    font-style: italic;
    padding: 3px;
    
    
    
}

.vendido{

    position: absolute;
    text-align: left;
    
}

.vendido-img{
    width: 50%;
    height: 50%;
}

.descripcion{

    padding:20px;
    text-align: justify;

}

.fecha{
    font-size: 12px;
}



@media (max-width: 400px){

    .graella{

        grid-template-columns: 90%;
        align-items: center;
        align-content: center;
        justify-content: center;
        
        
    }
    
   
}