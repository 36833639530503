.loading{

    text-align: center;
        
}

.loading img{

    width: 200px;
}
